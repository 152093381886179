import Vue from 'vue';
import router from '@/router/index';

export default {
  /**
   * Get list of Store
   */
  async getAllStores(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listStores`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listStores`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      storeName: query.name ?? '',
      storeNo: query.remarks ?? ''

    }
    try {
      //const response = JSON.parse('{"stores":[{"lastUpdated":"2022-01-26 10:06:49.0","storeNo":"0000","dateCreated":"2022-01-26 10:06:49.0","storeName":"test","id":2,"enabled":false,"remarks":""},{"lastUpdatedBy":"janchristian@apollo.com.ph","lastUpdated":"2022-01-24 18:01:46.0","storeNo":"0166","dateCreated":"2022-01-24 14:23:45.0","id":1,"enabled":true,"remarks":"Enabled by: janchristian@apollo.com.ph"}],"pageSize":1,"message":"Success","statusCode":"200"}');
      //return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of stores." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Disable Store
   */
   async enableStore(item) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/enableStore`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/enableStore`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      id: item.id,
      storeNo: item.storeNo
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error disabling store." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Updatable Tables
   */
   async getAllUpdatableTables(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listUpdatableTables`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listUpdatableTables`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString()

    }
    try {
      //const response = JSON.parse('{"pageSize":1,"message":"Success","updatableTables":[{"searchableFields":"store_no, threshold_type","lastUpdated":"2022-01-27 11:18:35.0","dateCreated":"2022-01-27 11:18:35.0","allowedRoles":"ROLE_UPDATE_TABLE,ROLE_UPDATE_THRESHOLD","description":"description store_threshold description","id":1,"tableName":"store_threshold"}],"statusCode":"200"}');
      //return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of updatable tables." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Table Values
   */
   async getAllTableValues(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/listTableData`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/listTableData`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      tableName: query

    }
    try {
      // const response = JSON.parse('{"pageSize":1,"storeThresholds":[{"storeNo":"0166","disableLimit":5,"disableCount":0,"storeName":"test","id":1,"defaultThreshold":2000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.count","additionalThreshold":3000},{"storeNo":"0166","disableLimit":3,"disableCount":5,"id":2,"defaultThreshold":1000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.amount","additionalThreshold":1000}],"message":"Success","statusCode":"200"}');
      // return response;
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of table values." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  /**
   * Get list of Table Values
   */
   async UpdateTableValue(params) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/threshold/updateTableData`
    if(true || process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/cb/threshold/updateTableData`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    try {
      // const response = JSON.parse('{"storeThreshold":{"storeNo":"0166","disableLimit":5,"disableCount":0,"storeName":"test","id":1,"defaultThreshold":2000,"thresholdEnabled":true,"thresholdType":"cb.daily.total.count","additionalThreshold":3000},"message":"Success","statusCode":"200"}');
      // return response;
      const response = await Vue.axios.put(url, params, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of updatable tables." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },
  getError(error) {
    if(error && error.response && error.response.data) {
      console.log(error.response)
      if(error.response.data.message) {
        return { error: error.response.data.message + ""};
      } else if(error.response.data.error) {
        return { error: error.response.data.error + ""};
      } else {
        return { error: error.response.data + ""};
      }
    } else {
      return { error: error + "" };
    }
  },


  //new circuit breaker APIs july 24, 2024
  async getAllStoreRecord(offset, limit, query) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/record/search`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/tdm/record/search`
    }
    const config = {
      headers: {
        Authorization: `${router.app.$keycloak.token}`
      }
    }
    const body = {
      pageNo: offset.toString(),
      pageSize: limit.toString(),
      storeId: query.storeId ?? ''
    }
    try {
      const response = await Vue.axios.post(url, body, config);
      console.log(response);
      if (response && response.data && response.status == 200) {
        return response.data;
      } else if (response && response.data && response.data.message) {
        return { error: response.data.message };
      } else {
        return { error: "Sorry. Error getting list of store records." };
      }
    } catch (error) {
      console.log(error);
      return this.getError(error)
    }
  },

  async createStoreRecord(formData) {
    /**Use when CORS issue is resolved, directly communicate to server without proxy*/
    var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/record/create`
    if(process.env.VUE_APP_IS_PROXY === "true") {
      /**Use when CORS issue persists, and use proxy*/
      url = `/tdm/record/create`
    }
    const config = {
      headers: {
        'Authorization': `${router.app.$keycloak.token}`,
        'Content-Type': 'application/json'
      }
    }
    const body = {
      storeId: formData.storeId,
      // currentDay: formData.currentDay,
      // totalDailyTransactions: formData.totalDailyTransactions,
      // currentDayTotal: formData.currentDayTotal,
      // currentHour: formData.currentHour,
      // currentHourTotal: formData.currentHourTotal,
      dailyLimit: formData.dailyLimit,
      hourlyLimit: formData.hourlyLimit,
      // lastUpdated: formData.lastUpdated,
      // lastUpdatedBy: formData.lastUpdatedBy,
   }
   try {
     const response = await Vue.axios.post(url, body, config);
     console.log(response);
     if (response && response.data && response.status == 200) {
       /*
        * Response.data - JSON object and contains:
        * message - string
        * statusCode - string; http status code
        */
       return response.data;
     } else if (response && response.data && response.data.message) {
       /*
        * Response.data JSON object and contains:
        * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
        * status - int; http status code; optional response
        * statusCode - string; http status codel optional response
        * message - string; always
        * expired - boolean; optional response
        */
       return { error: response.data.message };
     }
   } catch (error) {
     /**
      * Exception error
      */
     console.log(error);
     return { error: error.response.data.message };
   }
 },

 async updateStoreRecord(formData) {
  /**Use when CORS issue is resolved, directly communicate to server without proxy*/
  var url = `${process.env.VUE_APP_CIRCUIT_BREAKER_URL}/record/create`
  if(process.env.VUE_APP_IS_PROXY === "true") {
    /**Use when CORS issue persists, and use proxy*/
    url = `/tdm/record/create`
  }
  const config = {
    headers: {
      'Authorization': `${router.app.$keycloak.token}`,
      'Content-Type': 'application/json'
    }
  }
  const body = {
    id: formData.id,
    storeId: formData.storeId,
    currentDay: formData.currentDay,
    totalDailyTransactions: formData.totalDailyTransactions,
    currentDayTotal: formData.currentDayTotal,
    currentHour: formData.currentHour,
    currentHourTotal: formData.currentHourTotal,
    dailyLimit: formData.dailyLimit,
    hourlyLimit: formData.hourlyLimit,
    lastUpdated: formData.lastUpdated,
    lastUpdatedBy: formData.lastUpdatedBy,
 }
 try {
   const response = await Vue.axios.post(url, body, config);
   console.log(response);
   if (response && response.data && response.status == 200) {
     /*
      * Response.data - JSON object and contains:
      * message - string
      * statusCode - string; http status code
      */
     return response.data;
   } else if (response && response.data && response.data.message) {
     /*
      * Response.data JSON object and contains:
      * timestamp - datestring; yyyy-MM-ddTHH:mm:ss.SSS+00:00; optional reponse
      * status - int; http status code; optional response
      * statusCode - string; http status codel optional response
      * message - string; always
      * expired - boolean; optional response
      */
     return { error: response.data.message };
   }
 } catch (error) {
   /**
    * Exception error
    */
   console.log(error);
   return { error: error.response.data.message };
 }
},
}
